import {Module, Mutation, MutationAction, VuexModule} from 'vuex-module-decorators'
import {appInstance} from '@/utils/app-accessor'

@Module({name: 'adminRuntime', stateFactory: true, namespaced: true})
export default class AdminRuntimeStore extends VuexModule {
    notificationTemplates = []
    notificationTemplatesRecipientType = 'B2C'
    showTranslationKeys = false
    cachedLocaleMessages = null

    @Mutation
    SET_NOTIFICATION_TEMPLATES_RECIPIENT_TYPE(type) {
        this.notificationTemplatesRecipientType = type
    }

    @Mutation
    TOGGLE_TRANSLATION_KEYS({showTranslationKeys, $i18n}) {
        const locale = $i18n.locale
        if (showTranslationKeys) {
            this.cachedLocaleMessages = Object.freeze($i18n.getLocaleMessage(locale))
            $i18n.setLocaleMessage(locale, {})
            $i18n.fallbackLocale = ''
            this.showTranslationKeys = showTranslationKeys
        } else if (this.cachedLocaleMessages) {
            $i18n.setLocaleMessage(locale, this.cachedLocaleMessages)
            $i18n.fallbackLocale = 'en'
            this.showTranslationKeys = showTranslationKeys
        }
    }

    @MutationAction({mutate: ['notificationTemplates']})
    async loadNotificationTemplates() {
        const data = await appInstance.$api.notificationTemplates.get()
        return {notificationTemplates: data.templates}
    }
}
